/*
 * Simple responsive layout based on:
 *
 * https://www.w3schools.com/howto/howto_css_image_grid_responsive.asp
 */

.photoGalleriesRow {
    display: flex;
    flex-wrap: wrap;
}

/* Create four equal columns that sits next to each other */
.photoGalleryColumn {
    flex: 75%;
    max-width: 75%;
    padding: 5px;
    text-align: center;
}

.photoGalleryColumn img {
    margin-top: 8px;
    vertical-align: middle;
    width: 100%;
    padding: 5px;
}

/* Responsive layout - makes a two column-layout instead of four columns */
@media screen and (max-width: 800px) {
    .photoGalleryColumn {
        flex: 100%;
        max-width: 100%;
    }
}

/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width: 600px) {
    .photoGalleryColumn {
        flex: 100%;
        max-width: 100%;
    }
}