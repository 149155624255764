/*
 * Simple responsive menu based on:
 *
 * https://www.w3schools.com/howto/howto_js_topnav_responsive.asp
 *
 * App layour is based on:
 *
 * https://www.w3schools.com/cssref/tryit.asp?filename=trycss3_media3
 */

.sideBar {
    background-color: lightgray;
    flex: 30%;
    max-width: 300px;
    padding: 10px;
}

.mainContent {
    flex: 70%;
    padding: 10px;
}

.contentWrapper {
    display: flex;
    flex-wrap: wrap;
}

.navBar {
    overflow: hidden;
    background-color: #333;
}

.navBar .navLink {
    float: left;
    display: block;
    color: #f2f2f2;
    text-align: center;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
}

.navBar .navLink:hover {
    background-color: #ddd;
    color: black;
}

.navBar .navLinkActive {
    background-color: #04AA6D;
    color: white;
}

.menuIcon {
    margin-top: -0.2em; /* Small hack to align the hamburger and the text */
    margin-right: 5px;
    width: 24px;
    height: 24px;
}

#menuNavLink {
    display: none;
}

@media screen and (max-width: 800px) {
    .navBar .navLink {display: none;}
    .navBar .menuButton {
        display: block;
    }
    #menuNavLink {
        display: block;
    }

    /* Hide the sidebar */
    .sideBar {
        display: none;
    }
}

@media screen and (max-width: 800px) {
    .navBar.responsive {position: relative;}
    .navBar.responsive .menuButton {
        position: absolute;
        right: 0;
        top: 0;
    }
    .navBar.responsive .navLink {
        float: none;
        display: block;
        text-align: left;
    }
}