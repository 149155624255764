.historyTitle {
    text-align: center;
    font-weight: bold;
    font-size: larger;
}

.historySubTitle {
    text-align: center;
    font-weight: bold;
    margin-bottom: 20px;
}

.historySectionTitle {
    font-weight: bold;
}

.historyImageLeft {
    padding: 10px;
    float: left;
    width: 50%;
}

.historyImageCenter {
    padding: 10px;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
}