.postPhotosThumbs {
    background-color: #e3e1e1;
    padding: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.postPhotosThumb {
    margin: 5px;
}